import React, { useEffect, useState, useContext } from "react";
import {
	Grid,
	Flex,
	Col,
	Box,
	SubTitle,
	Text,
	Svg,
	Input,
	Button,
	Alert,
	Calendar,
} from "@components";

import { EditarAcceso } from "@modules";
import { Layout } from "@layout";

import AdminContext from "@context";
import initialState from "@states/afiliado/perfil";
import { getPerfil, guardarPerfil } from "@services/afiliados";
import { validate } from "@utils";

import icon from "@assets/logo.svg";
import setting from "@assets/icons/setting.svg";

function PagePerfil() {
	const {
		notify,
		setNotify,
		auth: { token, unique_code },
	} = useContext(AdminContext);

	const [perfil, setPerfil] = useState(initialState);
	const [enableButton, setEnableButton] = useState(false);
	const [editAccesos, closeEditAccesos] = useState(false);

	const updatePerfil = (key, value) =>
		setPerfil({
			...perfil,
			[key]: value,
		});

	const savePerfil = () => {
		guardarPerfil(perfil, token)
			.then(() =>
				setNotify({
					...notify,
					success: true,
					message: "Perfil actualizado correctamente!",
				})
			)
			.catch(() =>
				setNotify({
					...notify,
					error: true,
					message: "No hemos podido actualizar tu perfil!",
				})
			);
	};

	useEffect(() => {
		getPerfil(unique_code, token).then((newPerfil) =>
			setPerfil({ ...perfil, ...newPerfil })
		);
	}, []);

	useEffect(() => {
		console.log(perfil);
		setEnableButton(
			perfil.name &&
				perfil.lname &&
				+perfil.edad > 18 &&
				+perfil.edad < 100 &&
				perfil.birtdate &&
				perfil.phone?.length === 10 &&
				perfil.email &&
				validate.curp(perfil.curp).isValid &&
				validate.rfc(perfil.rfc).isValid &&
				perfil.country &&
				perfil.langs &&
				perfil.postalCode?.length === 5 &&
				perfil.state &&
				perfil.city &&
				perfil.delegacion &&
				perfil.colonia &&
				perfil.noInt &&
				perfil.noExt &&
				perfil.street
		);
	}, [perfil]);

	return (
		<Layout>
			<EditarAcceso
				show={editAccesos}
				onClose={() => closeEditAccesos(false)}
			/>

			<Grid className="pv:2">
				<Flex justify="center" className="mt:2">
					<Svg icon={icon} width={120} height={120} xs="120:120" />
				</Flex>

				<Flex justify="center" className="mt:1">
					<Flex inline align="center" justify="center" wrapper>
						<Text align="center" fw={500} fs={28}>
							{perfil.name} {perfil.lname}
						</Text>
						<Svg
							style={{ cursor: "pointer" }}
							onClick={() => closeEditAccesos(true)}
							className="ml:05"
							icon={setting}
							width={30}
							height={30}
							xs="30:30"
						/>
					</Flex>
				</Flex>

				<Text align="center" fw={500} fs={16} opacity={0.6}>
					{perfil.unique_code}
				</Text>

				<Flex justify="center" className="mt:2">
					<Col md={12}>
						<Box>
							<Flex>
								<Alert type="info" align="center" className="mb:2" mw="100%">
									<Text fs={12} fw={300}>
										Si no encuentras lo que buscas tranquilo(a) publicamos
										nuevas actualizaciones cada 15 días asegurando la
										implementación de lo que necesitas.
									</Text>
								</Alert>
								<Col className="ph:1">
									<SubTitle>Datos básicos</SubTitle>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.name}
										getValue={(v) => updatePerfil("name", v)}
										placeholder="Nombres"
										error={
											perfil.name?.length > 0
												? perfil.name?.length < 3
													? "Nombre invalido"
													: ""
												: ""
										}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.lname}
										getValue={(v) => updatePerfil("lname", v)}
										placeholder="Apellidos"
										error={
											perfil.lname?.length > 0
												? perfil.lname?.length < 3
													? "Apellido invalido"
													: ""
												: ""
										}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.edad}
										getValue={(v) => updatePerfil("edad", v)}
										placeholder="Edad"
										error={
											perfil.edad > 0
												? perfil.edad < 18
													? "Debes ser mayor de edad"
													: ""
												: ""
										}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Calendar
										value={perfil.birtdate}
										placeholder="Fecha de nacimiento"
										getValue={({ date }) => updatePerfil("birtdate", date)}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.phone}
										getValue={(v) => updatePerfil("phone", v)}
										placeholder="Número de teléfono"
										maxLength={10}
										error={
											perfil.phone?.length > 0
												? perfil.phone?.length < 10
													? "Número de telefono invalido"
													: ""
												: ""
										}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										type="email"
										value={perfil.email}
										disabled
										getValue={(v) => updatePerfil("email", v)}
										placeholder="Correo electrónico"
									/>
								</Col>

								<Col className="ph:1">
									<SubTitle>Datos Llave</SubTitle>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.curp}
										getValue={(v) => updatePerfil("curp", v.toUpperCase())}
										placeholder="CURP"
										maxLength={18}
										error={validate.curp(perfil.curp).message}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.rfc}
										getValue={(v) => updatePerfil("rfc", v.toUpperCase())}
										placeholder="RFC"
										maxLength={13}
										error={validate.rfc(perfil.rfc).message}
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.country}
										getValue={(v) => updatePerfil("country", v)}
										placeholder="Región"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.langs}
										getValue={(v) => updatePerfil("langs", v)}
										placeholder="Idiomas"
									/>
								</Col>

								<Col className="ph:1">
									<SubTitle>Dirección</SubTitle>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.country}
										getValue={(v) => updatePerfil("country", v)}
										placeholder="Pais"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.postalCode}
										getValue={(v) => updatePerfil("postalCode", v)}
										placeholder="Código Postal"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.state}
										getValue={(v) => updatePerfil("state", v)}
										placeholder="Estado"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.city}
										getValue={(v) => updatePerfil("city", v)}
										placeholder="Ciudad"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.delegacion}
										getValue={(v) => updatePerfil("delegacion", v)}
										placeholder="Delegación o municipio"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.colonia}
										getValue={(v) => updatePerfil("colonia", v)}
										placeholder="Colonia"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.street}
										getValue={(v) => updatePerfil("street", v)}
										placeholder="Calle #"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.noInt}
										getValue={(v) => updatePerfil("noInt", v)}
										placeholder="No. interior"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.noExt}
										getValue={(v) => updatePerfil("noExt", v)}
										placeholder="No. exterior"
									/>
								</Col>

								<Col className="ph:1">
									<SubTitle>Información adicional</SubTitle>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.website}
										getValue={(v) => updatePerfil("website", v)}
										placeholder="Link del sitio web"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.facebook}
										getValue={(v) => updatePerfil("facebook", v)}
										placeholder="Link perfil de Facebook"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.instagram}
										getValue={(v) => updatePerfil("instagram", v)}
										placeholder="Link perfil de Instagram"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.twitter}
										getValue={(v) => updatePerfil("twitter", v)}
										placeholder="Link perfil de Twitter"
									/>
								</Col>

								<Col md={10} className="mb:15 ph:1">
									<Input
										value={perfil.youtube}
										getValue={(v) => updatePerfil("youtube", v)}
										placeholder="Link perfil de YouTube"
									/>
								</Col>

								<Flex justify="end" className="mt:2 ph:1">
									<Button
										onClick={() => savePerfil()}
										disabled={!enableButton}
										square
										primary
									>
										Guardar cambios
									</Button>
								</Flex>
							</Flex>
						</Box>
					</Col>
				</Flex>
			</Grid>
		</Layout>
	);
}

export default PagePerfil;
